/* General styles */

body {
    margin: 0;
    padding: 0;
    background-color: #333;  
    color: #fff;
    font-family: Arial, sans-serif;
  }
  
  .login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;  
    background-color: #333; 
    
    /*

    --u:  15px;
    --c1: #fbd163;
    --c2: #e4a228;
    --c3: #d68f2b;
    --c4: #4c302f;
    --gp: 50%/ calc(var(--u) * 10) calc(var(--u) * 17.2);
    --bp: calc(var(--u) * -5) calc(var(--u) * -8.6);
    height: 100vh;
    --bg: 
		conic-gradient(from 0deg at 76.75% 18.25%, var(--c4) 0 60deg, var(--c3) 0 120deg,  var(--c1) 0 120deg, #fff0 0 360deg) var(--gp),
		conic-gradient(from -120deg at 23.5% 18.25%, var(--c3) 0 60deg,  var(--c1) 0 120deg, #fff0 0 360deg) var(--gp),
		conic-gradient(from -32deg at 50% 25%, var(--c2) 0 64deg, #fff0 0 360deg) var(--gp),
		conic-gradient(from 0deg at 77% 33.25%, var(--c1) 0 120deg, #fff0 0 360deg) var(--gp),
		conic-gradient(from -120deg at 23% 33.25%, var(--c4) 0 120deg, #fff0 0 360deg) var(--gp),
		conic-gradient(from -60deg at 50% 42%, var(--c1) 0 60deg, var(--c4) 0 120deg, #fff0 0 360deg) var(--gp),
		conic-gradient(from -60deg at 73% 50%, var(--c3) 0 150deg, #fff0 0 360deg) var(--gp),
		conic-gradient(from -90deg at 27% 50%, var(--c3) 0 150deg, #fff0 0 360deg) var(--gp),
		conic-gradient(from -90deg at 50% 50%, var(--c4) 0 90deg, var(--c1) 0 180deg, #fff0 0 360deg) var(--gp)
		;
    background: var(--bg), var(--bg);
    background-position: var(--bp), var(--bp), var(--bp), var(--bp), var(--bp), var(--bp), var(--bp), var(--bp), var(--bp),	0 0, 0 0, 0 0, 0 0, 0 0, 0 0, 0 0, 0 0, 0 0;
 */
 /*
   --u: 2.5vmin;
    --c1: hsl(0deg 0% 0%);
    --c2: hsl(212deg 4% 9%);
    --c3: hsl(212deg 7% 36%);
    --gp: 50%/calc(var(--u) * 5) calc(var(--u) * 10);
    --bp: calc(var(--u) * -2.5) calc(var(--u) * -5);
    height: 100vh;
    --bg: 
		radial-gradient(circle at 50% 25%, var(--c2) 23%, #fff0 25% 100%) var(--gp), 
		conic-gradient(from 270deg at 34% 46%, var(--c2) 0 12.5%, #fff0 0 100%) var(--gp), 
		conic-gradient(from 45deg at 66% 46%, var(--c2) 0 12.5%, #fff0 0 100%) var(--gp), 
		conic-gradient(from 180deg at 50% 80%, var(--c2) 0 12.5%, #fff0 0 100%) var(--gp), 
		conic-gradient(from 135deg at 50% 80%, var(--c2) 0 12.5%, #fff0 0 100%) var(--gp), 
		conic-gradient(from 0deg at 0% 20%, var(--c2) 0 12.5%, #fff0 0 100%) var(--gp), 
		conic-gradient(from -45deg at 100% 20%, var(--c2) 0 12.5%, #fff0 0 100%) var(--gp), 
		linear-gradient(180deg, var(--c2) 0 4.5%, #fff0 0 45.25%, var(--c2) 0 50%, #fff0 0% 100%) var(--gp), 
		radial-gradient(circle at 50% 25%, #fff0 0 25%, var(--c2) 26% 32%, #fff0 0 100%) var(--gp), 
		linear-gradient(165deg, var(--c3) -15%, var(--c1) 44%, #fff0 0 100%) var(--gp), 
		linear-gradient(180deg, var(--c1) 50%, #fff0 0 100%) var(--gp);
    background: var(--bg), var(--bg);
    background-position: 
		var(--bp), var(--bp), var(--bp), var(--bp), var(--bp), var(--bp), var(--bp), var(--bp), var(--bp), var(--bp), var(--bp), 
		0 0, 0 0, 0 0, 0 0, 0 0, 0 0, 0 0, 0 0, 0 0, 0 0, 0 0;
	background-color: var(--c2); 
 */
 
    --s: 50px;
    --c: #191b22;
    --_s: calc(2*var(--s)) calc(2*var(--s));
    --_g: 35.36% 35.36% at;
    --_c: #0000 66%,#20222a 68% 70%,#0000 72%;
    background: 
      radial-gradient(var(--_g) 100% 25%,var(--_c)) var(--s) var(--s)/var(--_s), 
      radial-gradient(var(--_g) 0 75%,var(--_c)) var(--s) var(--s)/var(--_s), 
      radial-gradient(var(--_g) 100% 25%,var(--_c)) 0 0/var(--_s), 
      radial-gradient(var(--_g) 0 75%,var(--_c)) 0 0/var(--_s), 
      repeating-conic-gradient(var(--c) 0 25%,#0000 0 50%) 0 0/var(--_s), 
      radial-gradient(var(--_c)) 0 calc(var(--s)/2)/var(--s) var(--s) var(--c);
    background-attachment: fixed;  
  }
 
  .form-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff; 
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);  
    width: 400px;
    height: 600px;
  }
  
  .form-title {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #3f72af;  
    height: 130px;
    width: 400px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    margin-bottom: 0px;  
  }
  
  .form-title img {
    max-width: 650px;
    width: 350px;
  }
  
  .login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    width: 100%;  
  }
  
  .input-group {
    display: flex;
    flex-direction: column;
    width: 100%;  
  }
  .logo {
    display: flex;
    justify-content: center;
    margin-top: 20px;
     width: 200px;
  }
  .labelStandard {    
    margin-bottom: 15px; 
    margin-top: 25px;
    font-size: 13px;       
    color: #000000;
  }
  .labelValidation {       
    margin-bottom: 15px; 
    margin-top: 25px;
    font-size: 13px;       
    color: rgb(199, 49, 49);
  }

  .labelInfo {    
    margin-top: 10px;  
    font-size: 13px;       
    color:rgb(7, 7, 7);
  }
  .labelWarning {
    margin-top: 10px;  
    font-size: 13px;
    font-weight: bold;
  }
  .labelError {
    margin-top: 10px;  
    font-size: 13px;
    font-weight: bold;
    color: rgb(199, 49, 49);
    
  }
  
  
  .flexContainer {
    display: flex;
    align-items: center;
  }
  .paragraph
  {
    margin-bottom: 5px;    
    font-size: 14px;  
    padding-left: 10px;
      
    color: #6b93c3;
  }
  .labelCookies
  {
    margin-bottom: 5px;    
    font-size: 14px;  
    padding-left: 10px;
      
    color: #6b93c3;

  }
  
  .input {
    padding: 12px;
    border: 2px solid #c8cccf;
    border-radius: 1px;
    font-size: 14px;  
    transition: border-color 0.5s ease;
    width: 100%;  
    
  }
  
  .input:focus {
    border-color: #1e90ff;
    outline: none;
  }
  .inputIcon {
    margin-right: 8px; /* Space between icon and text */
  }

  
  .button {
    background-color: #306daa;
    color: #fff;
    height: 50px;
    /* padding: 12px 24px; */
    margin-top: 20px;
    border: none;
    border-radius: 2px; 
    
    cursor: pointer;
    font-size: 14px;
     
    transition: background-color 0.3s ease;
    width: 100%; /* Take up full width of the input group */
    
  }
  
  .button:hover {
    background-color: #0274e6;
  }
  