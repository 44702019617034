.footer {
    /* position: fixed; */
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #f5f5f5;
    color: #333;
    text-align: center;
    padding: 10px;
    

  }