
@keyframes moveToRight {
    from {
      left: 0;
    }
    to {
      left: 100%;
    }
  }
  
  .progress-outer {
    display: flex;
    align-items: center;
  }
  .progress-info {
    width: 30px;
    padding-left: 5px;
  }
  
  .progress {
    width: 100%;
    background: #eee;
    min-height: 1px;
    position: relative;
    overflow: hidden;
    border-radius: 3px;
  }
  
  .progress.progress--small {
    height: 3px;
  }
  
  .progress.progress--medium {
    height: 5px;
  }
  
  .progress.progress--large {
    height: 10px;
  }
  .progress-bar {
    background: #1976d2;
    height: 100%;
    position: absolute;
    left: 0;
  }
  
  .progress.progress--loading .progress-bar {
    width: 30%;
    animation: moveToRight 1s infinite linear;
    border-radius: 3%;
  }
  