/* DialogStyles.css */

/* Dialog container */
.dialog {
  .MuiDialogTitle-root {
    background-color: #1976d2;
    color: #fff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 4px 4px 0 0; /* Rounded top corners */
  }

  .MuiDialogContent-root {
    padding: 16px;
  }

  .MuiDialogActions-root {
    padding: 16px;
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #ccc; /* Separator between content and actions */
  }
}

/* Dialog title */
.MuiDialogTitle-root {
  font-size: 1.5rem;
}

/* Dialog content text */
.dialogContentText {
  color: #232222;
  line-height: 1.6;
}

/* Cancel button */
.cancelButton {
  color: #3f51b5;
  background-color: transparent;
  border: 1px solid #3f51b5;
  padding: 8px 16px;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
  cursor: pointer;
  font-weight: bold;

  &:hover {
    background-color: #3f51b5;
    color: #fff;
  }
}

/* Confirm button */
.confirmButton {
  color: #fff;
  background-color: #1976d2;
  border: 1px solid #1976d2;
  padding: 8px 16px;
  border-radius: 4px;
  transition: background-color 0.3s;
  cursor: pointer;
  font-weight: bold;

  &:hover {
    background-color: #1c64ac;
  }
}

/* Date picker container */
.datePickerContainer {
  display: flex;
  justify-content: left;
}

/* Date picker input */
.datePicker {
  border: 1px solid #ccc;
  padding: 8px 12px;
  font-size: 16px;
  border-radius: 4px;
  transition: border-color 0.3s;

  &:focus {
    outline: none;
    border-color: #1976d2;
  }
}
