/* permissions.css */

/* Global styles */
.PermissionManager {
    font-family: 'Open Sans', sans-serif; /* Change to Google Fonts */
    font-size: 14px;
    text-align: center;
    padding: 20px;
  }
  
  .Title {
    margin-bottom: 20px;
    align-items: left;
    align-content: left;
    text-align: left;
  }
  
  /* Permissions Container */
  .PermissionsContainer {
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: grid; /*grid*/
    grid-template-columns: repeat(5, 1fr); /* Display 4 regions in a row */
    gap: 20px;
    width: 90%; /* Adjust width as needed */
    margin: 0 auto; /* Center the container horizontally */
  }
  
  /* Region styling */
  .RegionCard {
    padding: 25px;
    text-align: left;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out;
    width: 100%;
    cursor: pointer;
  }
  
  

  .RegionCard:hover {
    background-color: #e0e0e0;
    transform: translateY(-3px);
  }
  
  .RegionCard.selected {
    background-color: #c0e6c0;
    box-shadow: 0 4px 10px rgba(0, 128, 0, 0.2);
    transform: translateY(-3px);
  }
  
  .RegionCard.indeterminate {
    background-color: #f0e68c;  
    box-shadow: 0 4px 10px rgba(139, 69, 19, 0.2);  
    transform: translateY(-3px);
  }  
  .RegionLabel {
    display: contents;
    align-items: left;
    padding: 5px 0;
    
    max-width: fit-content !important;
    color: #333;  

    
  }

  .RegionCheckbox {
    width: 20px;
    height: 20px;
    padding-right: 20px !important;     
    transition: background-color 0.2s ease-in-out;
   
  }

  /* Branch styling */
  .Branch {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  
  .BranchLabel {
    display: contents;
    align-items: center; /* Vertically align checkbox and text */
    padding-left: 30px !important; /* Adjust the left padding */
    font-size: 10px !important;
    max-width: fit-content !important;
    color: #4a4747; /* Text color for branch label */
  }
  
  .BranchCheckbox {
    width: 20px;
    height: 20px;
    padding-right: 20px !important;
    transition: background-color 0.2s ease-in-out;
   
  }
  
  .Branch:hover   {
    color: #333;
  }
  
  .Branch.selected   {
    color: #008000;
    font-weight: bold;
  }
  
  
  
  
  
  /* Button styling */
  .ButtonContainer {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
  
  .SaveButton {
    background-color: #008000;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }
  
  .SaveButton:hover {
    background-color: #006400;
  }
  


  .DeactivatedUserLabel {
    background-color: #ffcccc;
    border: 1px solid #ff0000;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
  }