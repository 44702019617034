/*.button {
    display: inline-block;
    padding: 10px 20px;
    margin: 1px;
    border: none;
    border-radius: 4px;
    background-color: #f0f0f0;
    color: #333;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }*/
  
  .button:hover {
    background-color: #ddd;
  }
  
  .button:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.1);
  }
  
  .button:active {
    background-color: #ccc;
  }
   
  .custom-button {
    display: flex;
    align-items: center;
    padding: 5px;
    background-color: transparent;
    border: 1px solid #007bff;
    border-radius: 4px;
     
    background-color: white; 
  color:#007bff; 
  
    cursor: pointer;
    height: 32px;  
  } 
   .custom-button:hover {
    background-color: rgb(219, 234, 251);
  }  
  /* Custom styles for the icon */
  .icon {
    margin-right: 8px;
  }
/*
   .custom-button2 {
    display: inline-block;
    padding: 10px 10px;
    margin-right: 10px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    background-color: #2196f3;
    color: #fff;
    transition: background-color 0.3s ease;
  }  
  
   .custom-button2:hover {
    background-color: #1582f0;
  }  */
  