@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;600&display=swap');

body {
  box-sizing: border-box;
  color: #635858;
  font-family: 'Inter', sans-serif;
  margin: 0;
  padding: 0;
}

.containers {
  width: 98%;
  max-width: 1200px;    
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
  background-color: #ffffff;
  box-shadow: 1px 4px 10px rgba(0, 0, 0, 0.3);
  padding: 10px;
  border-radius: 4px;
}

.table-containers {
  width: 100%; 
  overflow-x: auto;
  overflow-y: hidden;
  margin-top: 10px;
  position: relative;     
}

.tablex {
  border-collapse: collapse;
  width: 100%;
 
  font-family: 'Poppins', sans-serif;
  border: 3px solid #e2dede;
}

.tablex th {
  height: 50px;
  padding-left: 10px;
  font-size: 13px;
  border: 1px solid #c3bebe;
  text-align: center; /* Center align the text horizontally */
  vertical-align: middle; /* Center align the text vertically */
  background-color: #f5f5f5;
  color: #333333;
  position: sticky;
  top: 0;
  
  font-weight: 600;
}

/* Styles for the first column header */
.tablex th:first-child {
  background-color: #f5f5f5;
}

td:first-child {
  background-color: #f5f5f5;
}

.tablex tr {
  height: 35px;
  padding: 1px;
  border: 1px solid #e2dede;
  text-align: left;
  font-size: 13px;
  transition: background-color 0.2s;
  
}

.tablex tr:hover {
  background-color: #fdfff6  ;
}

.tablex td {
  padding: 6px;
  text-align: left;
  border-bottom: 1px solid #a9a9a9;
  border-right: 1px solid #a9a9a9;
}

.tablex tr.selected {
  background-color: #0078d7;
  color: white;
}

.tablex tr.selected input,
.tablex tr.selected select {
  background-color: #0078d7 !important;
  color: white !important;
  border-color: #0078d7;
  
}

.arrow-icon {
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid #333333;
  vertical-align: middle;
  margin-right: 4px;
  margin-left: 5px;
  padding-left: 0px;
}

.arrow-icon-2 {
  font-size: 12px;
  color: #333333;
  margin-right: 4px;
}

.cell-header:focus {
  outline: none;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
  background-color: lightblue;
  transition: box-shadow 0.2s ease, background-color 0.2s ease;
}

.editable-cell {
  background-color: transparent;
  padding: 6px;
  border: 2px solid transparent;
  font-weight: 300;
  transition: background-color 0.2s, border 0.2s;
}

/* Set the border to none when the input field is not in focus */
.editable-cell:not(:focus) {
  border: 2px solid transparent;
}

.editable-cell:focus {
  outline: none;
  border: 2px solid #3775e1;
  background-color: #f4f6de !important;
   
}

 

.centered-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  visibility: hidden;
}


/* Row Colors */
 .gold-row {
  background-color: rgba(245, 104, 3, 0.2);
}
 
.red-row {
  background-color: rgba(240, 128, 128, 0.2);
}

.lightgreen-row {
  background-color: rgba(0, 255, 0, 0.2);
}

.white-row {
  background-color: white;
}

.lightblue-row {
  background-color: #f0f7ff;
}

.lightgreenwhite-row {
  background: linear-gradient(to bottom, rgba(0, 255, 0, 0.2), white);
}  