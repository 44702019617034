.trackerstudent-table td{
     width :340px  ;
    align-items:normal;
}

.WithWidth
 {
    display:block;
    background-color: burlywood;
    width: 150px;
 }
 
.sidebyside{   
    
    display: flex;
    margin-bottom: 100px;
    flex :2  ;
    position:  absolute;
     /* width: 90%;  
     height:  90%;   */    
}