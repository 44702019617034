.panel {
    border: 0px solid #ccc;
    display: inline-block;    
    padding-bottom: 1px;    
  }
  
  .tag {
    font-size: 22px;     
    font-weight: bold;
    padding-left: 15px;
    padding-right: 5px;
  }
  
  .tag-black {
    
    background-color: rgb(86, 81, 81);
    color: white;
  }
  
  .tag-blue {
   
    background-color: #2486d6; /* Material-UI primary blue */
    color: white;
  }
  
  .tag-red {
    background-color: #ba1f27;
    font-size: 22px;   
    color: rgb(255, 255, 255);
  }
  