/* Colors */
/*:root {
  --color-success: #04AA6D;  
  --color-info: #2196F3;  
  --color-warning: #ff9800;  
  --color-danger: #f44336;  
  --color-gray: #e7e7e7; 
}
 */

/* General Styles */
.label {
  color: white;
  padding: 8px;
} 
.custom-container {
  margin-left: 20px;
  border-radius: 12px;  
  overflow: hidden;  
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);  
  margin-bottom: 20px;  
}

/* Tables */
.custom-table {
  width: 100%;
  border-collapse: collapse;
  font-family: 'Poppins', sans-serif;

}

.custom-table th,
.custom-table td {
  padding: 5px;  
  text-align: center;
  border: none;  
  
}

.custom-table th {
  background-color: #fdfcfc;  
  font-weight: bold;  
  height: 55px;
  font-size: 12px;  
  color: rgb(100, 97, 97);
  border-bottom: 1px solid #f0eaea;  
}

.custom-table tr {
  font-size: 13px;  
  height: 45px;
  border-bottom: 1px solid #f6f2f2; 
}
.custom-table tr:hover {
  background-color: #f5f5f5;
}
 

.custom-table td:first-child {
  background-color: #fdfcfc;  
  
}

 
.custom-input {
  font-weight: bold;
  background-color: #ffffff;
  border-radius: 2px;
  padding: 8px;  
  width: 100%;  
  box-sizing: border-box;  
  display: flex;  
}
 

.custom-input:focus {
  outline: none;
  width: 100%;
  border: 2px solid #aac4f4;
  background-color: #f4f6de;
  outline: 0px solid #ccc;
}

.custom-input:hover {
  background-color: #e1ecf8;
}

.input-wrapper input[type='text'] {
  display: flex;
  border: 1px solid rgb(246, 248, 252);
}

/* Cursor Styles */
.hand-cursor {
  cursor: pointer;
}

.none-cursor {
  cursor: auto;
}

 
/* Status cell */
 
.custom-status {
  
  display: flex; /* Use Flexbox to center the label */
  align-items: center;
  margin: 5px;
  justify-content: center;
  height: 35px;
  width: 120px;
   
  border: none;
  border-radius: 18px;
  color: #f9f7f7;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
}

.tooltip {
  position: absolute;
  top: -30px; /* Adjust the position of the tooltip above the label */
  left: 50%; /* Center the tooltip horizontally */
  transform: translateX(-50%); /* Center the tooltip horizontally */
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  z-index: 1; /* Ensure the tooltip appears above other elements */
}
