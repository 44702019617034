/* .dialog-title-box {
    background-color: #1976d2;
     
    margin-bottom: 20px !important;
    
    padding-top: 10px;
    
   
    font-weight: bold;
    font-size: 18px;
    color: white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);  
  } */

  .dialog-title-box {
    background-color: #1976d2;
    margin-bottom: 20px !important;
    padding: 10px 20px; /* Adjust padding for better spacing */
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    font-size: 18px;
    color: white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .dialog-title-text {
    flex: 1;
     
  }
  
  /* Add more styles if needed */
  