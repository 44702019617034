@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;600&display=swap');

/* CalculationDetailsDialog.css */

.custom-dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(66, 66, 68, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-dialog {
  background-color: white;
  padding: 12px;
  border-radius: 12px; /* Slightly rounded corners */
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  position: absolute;
}

.dialog-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 16px;
}

.table-container {
  max-height: 300px;
  position: relative;
  overflow: auto;
}

.table-header {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}

.data-table {
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  border: 1px solid #ddd;
  border-collapse: collapse;
}

.data-table th,
.data-table td {
  padding: 8px;
  text-align: center;
  border: 1px solid #ddd;
}

.data-table th {
  background-color: #f5f5f5;
  font-weight: 600;
}

.data-table tr:hover td {
  background-color: #f0f0f0;
}
 
.course-cell {
  text-align: left;
}

.number-cell {
  text-align: right;
}

.total-cell {
  font-weight: 600;
  text-align: right;
}

.custom-status-text {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 6px;
  height: 25px;
  width: 120px;
  border-radius: 10px;
  color: white;
  font-weight: 600;
  font-size: 12px;
  background-color: #4caf50; /* Green color */
  text-align: center;
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
  font-size: 30px;
  color: #ffffff;
}

.bottom-container {
  /* display: flex; */
  justify-content: left;
  margin-top: 12px;
}

.action-button {
  margin-right: 10px;
  background-color: #1976d2;
  color: white;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s ease-in-out;
}

.action-button:hover {
  background-color: #1565c0;
}
.check-box-label
{ 
 margin-bottom: 5px;   
  font-size: 20px;
  cursor: pointer;
}
.check-box { 
 margin-bottom: 5px;
  padding: 6px 10px;
  /* transform: scale(1.5);  */
    margin-right: 2px;
  cursor: pointer;
}

.label-date {

  font-size: 16px;
  margin-bottom: 5px;
  padding: 11px 15px;
    /* transform: scale(1.5);   */
      margin-right: 14px;
    cursor: pointer;
  }

.center-container {
  padding-top: 50px;
  padding-bottom: 30px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.bold-row {
  font-weight: 600;
  color: #1e3c47;
}

.empty-label {
  height: 100px;
}
