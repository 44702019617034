/* GroupSetup.css */

.group-setup {
    width: 650px;
    margin: 0 auto;
    background-color: #f7f9fc;
    border-radius: 10px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    padding: 20px;
  }
  
  .group-name {
    font-size: 15px !important;
    font-weight: bold !important;
    /* margin-bottom: 10px; */
  }
  .vertical-space {
    margin-top: 30px !important; /* Adjust the value as needed */
  }
  
  .warning-text {
    color: #cd4435;
    margin-bottom: 15px;
  }
  
  .section-title {
    font-size: 10px;
    margin-bottom: 15px;
  }
  
  .checkbox-label {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
  }
  
  .text-field {
    margin-top: 10px !important;
    padding: 5px !important;
  }
  
  .buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
  
  .reset-button {
    background-color: #f39c12;
    color: white;
    border-radius: 5px;
    padding: 8px 20px;
    transition: background-color 0.3s ease;
  }
   
  
  