/* MyComponent.css */
.select-wrapper {
    position: relative;
    display: inline-block;
    
  }
  
  .custom-select {
    height: 35px !important;
    border: 2px solid #ccc;
    border-radius: 4px;
    padding: 5px 2px 5px 5px;
    font-size: 14px;
    background-color: #fff;
    color: #333;
    outline: none;
    transition: border-color 0.3s ease;
    /* position: relative; */
    /* z-index: 1; */
    
    top: 0;     
    max-height: 100px; /* Adjust the maximum height as per your preference */
        
  }
  
 
  .custom-select:hover {
    background-color: #fdfff6  !important  ;
  }
   .custom-select:hover,
   .custom-select:focus {
    border-color: #007bff !important;
  }  
  
  .disabled-select select {
    pointer-events: none;
    background-color: #f1f1f1;
    /* Add any other styles you want for the disabled select here */
  }