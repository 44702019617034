.dialog-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    animation: fadeInOverlay 0.3s ease-in-out;
  }
  
  @keyframes fadeInOverlay {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  .dialog-box {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 60%;
    width: auto;
    padding: 20px;
    text-align: center;
    color: #333;
    animation: fadeInUp 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .dialog-box h2 {
    margin: 0 0 15px;
    padding: 10px 0;
    font-size: 1.6rem;
    /* background-color: #f5f5f5; */
    border-radius: 6px;
  }
  
  .dialog-box p {
    margin: 0 0 20px;
    font-size: 1.2rem;
  }
  
  .dialog-box::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #e0e0e0;
  }
  
  .button-container {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 15px;
  }
  
  .yes-button,
  .no-button {
    background-color: #f0f0f0;
    color: #333;
    border: none;
    border-radius: 6px;
    padding: 10px 20px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .yes-button:hover,
  .no-button:hover {
    background-color: #ddd;
  }
  