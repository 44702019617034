/* ControlPanel.css */

.control-panel {
    padding: 10px;
    display: flex;
    height: 100vh;
    background-color: #f0f0f0;
  }
  
  .tab-container {
    width: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 20px;
    box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.1);
    overflow: auto;
  }
  
  .tabs {
    display: flex;
    align-items: center;
  }
  
  .tab {
    padding: 10px 20px;
    cursor: pointer;
    border: 1px solid #f2f1f1; /* Add a border to all sides of the tab */
    background-color: transparent;
    color: #2c8ccc;
    font-weight: normal;
    border-bottom: none; /* Remove the bottom border */
    transition: border 0.3s ease-in-out;
  }
  
  .tab.active {
    border-bottom: 2px solid #3498db;
    color: #2980b9; /* Change color for active tab */
    font-weight: bold; /* Make font bold for active tab */
  }
  