/* TreeViewTrackerDiv */
.TreeViewTrackerDiv {
  width: 100%;

  max-width: 360px;
  height: 100%;
  /* margin: 0 auto;  */
  border: 2px solid #ccc;
  /* background-color: #f6f6f8; */
  text-align: left;
  font-size: 13px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  border-radius: 8px; 
  
  margin-top: 10px;
  padding: 10px; 
  display: flex; 
  
  flex-direction: column; 
}

/* Add a title to the .TreeViewTrackerDiv */
.TreeViewTrackerDiv::before {
  content: "Aufträge"; 
  display: block;
  width: 380px;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
  
}

/* TreeView */
.TreeView {
  /* flex: 1; */
  border-radius: 6px;
  height: 650px;
  max-height: 760px;  
  width: 390px;
  overflow-y: auto;
  border: 1px solid #3593f1;
  background: #f0eff5;
  font-size: 13px;
  text-align: left;
  align-items: center;
}

 
.TreeItemStyle {
  /* padding: 5px; */
  font-size: 14px;  
  color: #334d6a;
}

/* Refrsh And Search */
.containerSearch {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;  
}

/* Style the Refresh Button */
.refreshButton {
  padding: 8px 16px;  
  min-width: 120px;  
  background-color: #1180f7;  
  color: #ffffff;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  margin-left: 10px;
  margin-bottom: 20px;
  transition: background-color 0.3s, box-shadow 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Style the Refresh Button on hover */
.refreshButton:hover {
  background-color: #0056b3;  
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Style the Search Input */
.searchInput {
  padding: 10px 12px; 
  border-radius: 4px;
  border: 1px solid #ced4da;  
  font-size: 16px;
  width: 100%;
  max-width: 400px;  
  height: 38px;  
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 20px;
  transition: background-color 0.3s, box-shadow 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Style the Search Input on focus */
.searchInput:focus {
  background-color: #f8f9fa;  
  border-color: #6c757d;  
  outline: none;
}

 
